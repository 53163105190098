'use client';

import { ChevronDownIcon } from '@heroicons/react/16/solid';

import { useState, useEffect, useRef } from 'react';



function LinkGroup(props: {
  title: string,
  href?: string,
  links?: {
    title: string,
    href: string,
  }[],
  onLinkClick?: () => void,
  onLinkBlur?: () => void,
}) {
  const [open, setOpen] = useState(false);



  const handleClick = (passOnClick: boolean = true) => {
    setOpen(!open);

    if (passOnClick) {
      // if (!props.links || props.links.length === 0) {
      props.onLinkClick && props.onLinkClick();
      // }
    }
  }

  const handleBlur = () => {
    // if (!props.links || props.links.length === 0) {
    props.onLinkBlur && props.onLinkBlur();
    // }
  }

  return <div className='py-2 text-2xl'>
    {!props.href && <a
      className='font-normal text-white cursor-pointer select-none'
      // onClick={handleClick}
      // onBlur={handleBlur}
      onClick={() => handleClick(false)}
    >
      {props.title}
      {props.links && props.links.length > 0 && <ChevronDownIcon
        className={`
          cursor-pointer
          bg-neutral-50 rounded-full text-gray-500
          inline-block w-5 h-5 ml-2
          ${open ? 'transform rotate-180' : ''}
        `}
      />}
    </a>}
    {props.href && <a href={props.href}
      className='font-normal text-white hover:text-white'
      onClick={() => handleClick(true)}
      onBlur={handleBlur}
    >
      {props.title}
    </a>}
    {props.links && open && <div className='flex flex-col pl-2 mt-2'>
      {props.links.map((link, index) => <a
        onClick={() => handleClick(true)}
        onBlur={handleBlur}
        key={index}
        href={link.href}
        className='text-[#cdd6d5] py-2 hover:text-white'
      >
        - {link.title}
      </a>)}
    </div>}
  </div>
}

function Header() {

  const [dropDownOpen, setDropDownOpen] = useState(false);


  
  const headerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event:any) => {
      if (
        headerRef.current &&
        !headerRef.current.contains(event.target) &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setDropDownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  ////console.log('HEADER USER', JSON.stringify(USER));
  const portalText = 'Your Member Portal';


  return <><header className='fixed left-0 top-0 z-50 w-full min-w-[300] flex flex-col items-center'>


  <div className='relative md:pt-[30px] bg-[#440098] bg-main-purple bg-center bg-repeat w-full max-w-[1380px] h-[130px]'>
    
    <div className='relative flex flex-col w-full'>

      <div className='absolute top-[85px] flex flex-col justify-center w-full bg-[#ba29ba] text-[#ffcb00] font-bold italic xs:text-xs sm:text-xl md:text-3xl xs:pl-[120px] sm:pl-[250px] md:pl-[370px] p-2 whitespace-nowrap '>PLAY OUR TRIVIA GAME SHOW FOR MONEY!</div>
      <div className='absolute z-10 top-[30px] w-full h-28 flex items-center justify-between xs:pl-2 sm:pl-8'>

      <a href='/'>
        <img
          src='/assets/logo/fate-and-fortune-show-logo.png'
          alt='Fate and Fortune Logo'
          height={172}
          width={300}
          className="w-full xs:w-[100px] sm:w-48 md:w-60 lg:w-72 h-auto cursor-pointer"
          onClick={() => setDropDownOpen(false)}
        />
      </a>
      </div>
    </div>
    

    </div>

  </header>
  <div className='bg-[#440098] bg-main-purple bg-center bg-repeat w-full xs:min-h-[140px] md:min-h-[190px] '></div>
  </>
}

export default Header;