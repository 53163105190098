function Section(props: {
  id?: string,
  className?: string,
  children: React.ReactNode
}) {
  return <section
    id={props.id}
    className={`${props.className}`}
  >
    {props.children}
  </section>;
}

export default Section;
