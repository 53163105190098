import React, { useEffect, useState } from 'react'; // Import React for JSX
import { useParams } from 'react-router-dom';
import { isValidReferralID } from './utility/referral';

import Cookies from 'universal-cookie';


import StreamPreview from './components/StreamPreview';
import Accordion from './components/ui/Accordion';
import Section from './components/ui/Section';
import { dqueryAppSetting, dquery } from './service/dquery';
import Header from './components/header';
//APP IS DEPLOYED USING REACT, NOT NEXTJS
function ReferralPage(): JSX.Element {

const cookies = new Cookies();
const { referralID } = useParams();

const [appSetting, setAppSetting] = useState({homeIntroDescription: '', homeIntroStreamUrl: '', homeWinnerDescription: '', homeWinnerStreamUrl: ''});


  useEffect(() => {

    async function fetchAppSetting() {
      try {
        const appSetting:any = await dqueryAppSetting ();
  
        if (appSetting)
          setAppSetting(appSetting);
        //console.log('appSetting: ' + JSON.stringify(appSetting));
  
      } catch (error) {
        console.error('Error fetching app setting:', error);
      }
    }
  
  
    fetchAppSetting();

    queryShow ();

  }
  , []);



useEffect(() => {

// Validate referral ID (as in previous responses)  

    if (referralID) {
        // Validate referral ID (as in previous responses)
        if (isValidReferralID(referralID)) {
            // Set cookie
            //console.log ('referralID: saved: ' + referralID)

            cookies.set('referralID', referralID, { path: '/', maxAge: 31536000, domain: '.fateandfortune.com' }); //1 years
        }
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [referralID]);

async function queryShow () {
  try {
    const showData:any = await dquery('shows');

    //this stageStatus is provided by the backend dquery shows api
    //Latest Show OR Next Show

    if (!showData)
    {
      return;
    }

/* 
    if (showData.showsUpcoming)
    {
      setShowUpcoming(JSON.parse(showData.showsUpcoming));    
    }

    //console.log ('showUpcoming: ' + JSON.stringify(showUpcoming));  

    showData.shows.map ((show:any) => { 

      if (show.stageStatus === 'Latest Show') {
        setShowLatest(show);

        //console.log('showLatest: ' + JSON.stringify(show));
      }
      else if (show.stageStatus === 'Next Show') {
        setShowNext(show);
      }
    }); */
      
    //console.log('showData: ' + JSON.stringify(showData));

  } catch (error) {
    console.error('Error fetching showData:', error);
  }
}



function Hero() {

  return <Section id='hero' className='flex flex-col w-full border-b-[24px] border-[#00adee]'>
    
    <div className="relative flex flex-row w-full justify-center xs:min-h-[1100px] sm:min-h-[1100px] md:min-h-[680px]">

      <div className="absolute z-10 top-0 left-0 flex flex-col text-2xl text-white gap-8 p-12 w-full xs:items-center sm:items-start">
        <div><img
            src='/assets/text/img_JustWatchAndWin_Title@1x.png'
            alt='Fate & Fortune Logo'
            height={151}
            width={400}
          />
        </div>
        <div className='md:w-1/2 md:max-w-[500px] italic'>$10,000 Cash Prizes Available Every Show.<br/><br/>
        Hundreds of cash prize winners every month!<br/><br/>
        $25,000 Cash Play-off every month just for playing or winning <span className='text-[#FBDD77]'>Trivia For Cash!</span><br/><br/>
        Free to join, free to play, free to win on every show!</div>

        <a className='flex whitespace-nowrap xs:w-[300px] xs:h-[70px] sm:w-[360px] sm:h-[85px] justify-center items-center text-[#f8cc00] font-extrabold text-4xl italic bg-btn-subscribe bg-center bg-no-repeat bg-[length:100%_100%]' href="https://fateandfortune.com/login/google">SIGN UP</a>
      
      </div>

      <div className="absolute bottom-0 right-0 z-0 flex flex-col text-3xl text-white w-[100%] items-end">
        <img
            src='/assets/bg/img_Man@1x.png'
            alt='Man with cash'
            height={812}
            width={800}
          />
    
      </div>
    </div>
  </Section>
}



function HowItWorks() {
  const items = [
    {
      title: 'JOIN FOR <span style="color: #FBDD77">FREE</span> BY SUBSCRIBING TO OUR FATE AND FORTUNE YOUTUBE CHANNEL. SIGN IN WITH GOOGLE BUTTON TO SEE YOUR AUTO-GENERATED FORTUNE NUMBER IN YOUR PERSONAL PORTAL.',
      //content: 'no sensitive info required'
    },
    {
      title: 'PLAY & WIN WITH OUR FATE AND FORTUNE TRIVIA GAME BY ANSWERING ALL 3 TRIVIA QUESTIONS. WIN CASH AND EARN TRIVIA POINTS FOR EVERY GAME YOU PLAY.',
      //content: 'Guaranteed winners per show'
    },
    {
      title: 'WATCH & WIN BY SIMPLY WATCHING OUR FATE AND FORTUNE GAME SHOWS. EVERY FORTUNE NUMBER ARE AUTOMATICALLY ENTERED INTO RANDOM WINNER CASH GIVEAWAYS. ',
      //content: 'it\'s that simple'
    },
    {
      title: '<span style="color: #FBDD77">*BONUS!*</span> REFER FRIENDS TO EARN UP TO 10 EXTRA FORTUNE NUMBERS AND BONUS TRIVIA POINTS, INCREASING YOUR CHANCES OF WINNING ON EVERY SHOW!',
      //content: 'it\'s that simple'
    }
  ]

/*    <h2 className='text-cyellow-500 text-xl'>
              {item.title}
            </h2>
            <p className='text-white'>
              {item.content}
            </p>*/

  return <Section id='how-it-works' className='flex flex-col items-center w-full bg-fpurple bg-main-pink bg-center bg-repeat border-b-[24px] border-[#440098] py-10'>


    <div className='relative w-full min-h-[680px] md:bg-btn-youtube-graphic md:bg-top md:bg-no-repeat md:bg-[length:300px]'>


    <div className=" flex flex-wrap w-full">
        <div className="text-white gap-8 xs:p-4 md:p-12 w-full md:w-1/2">
          <img
            src='/assets/text/img_HowItWorks_Title@1x.png'
            alt='How It Works Title'
            height={151}
            width={400}
          />

          <div className='flex flex-col'>
            <ul className='flex flex-col'>
              {items.map((item, index) => (
                <li className='relative flex items-center p-0 gap-1 space-y-5 w-full' key={index}>
                  <div className='flex flex-col justify-center items-center text-center min-w-[100px]'>
                    <div className='flex flex-col justify-center items-center text-[#ffcc00] pt-5 p-2 font-indarung-personal bg-[#440098] text-[3rem] w-[5rem] h-[5rem] rounded-full'>
                      {index + 1}
                    </div>
                  </div>
                  <div className='text-white text-xl uppercase'>
                    <div dangerouslySetInnerHTML={{ __html: item.title }} />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex flex-col w-full md:w-1/2 p-6"> 
          <div className="flex flex-col w-full border-[15px] border-white rounded-2xl"> 

          <div className='flex flex-col items-center justify-center  w-full min-h-[300px] border bg-black border-black rounded-[10px] '>
            <div className='flex flex-col gap-4 items-center justify-center w-[200px] h-[141px]'>
              <img
                src='/assets/img/youtube-play-btn_sm.png'
                alt='Show Coming Soon'
                width={200}
                height={141}
                style={{ width: '50%', height: '50%', borderRadius: '10px' }}
              />
              <div className='flex items-center justify-center text-2xl text-white'>
                COMING SOON!!!
              </div>
            </div>
          </div>

            {/* <StreamPreview streamUrl={appSetting.homeIntroStreamUrl}/> */}

          </div>

          <div className="flex flex-col w-full justify-center items-center gap-8 mt-8"> 
            <a className='flex whitespace-nowrap w-[280px] h-[85px] justify-center items-center text-[#f8cc00] font-bold text-4xl italic bg-btn-subscribe-purple-dark bg-center bg-no-repeat bg-[length:100%_100%]' href="https://fateandfortune.com/login/google">SIGN UP</a>
            <a className='flex whitespace-nowrap w-[360px] h-[85px] justify-center items-center text-white font-bold text-4xl italic bg-btn-refer bg-center bg-no-repeat bg-[length:100%_100%]' href="https://fateandfortune.com/login/google">REFER A FRIEND</a>
      
          </div>

        </div>
      </div>
    </div>

  </Section>
}


return <main className='flex flex-col items-center w-full bg-fpurple bg-main-purple bg-center bg-repeat'>
  
  <Header />
{/*   <AgeRestrctionBanner /> */}
  
  <Hero />
  <HowItWorks />
</main>;
}

export default ReferralPage;
